import { Box } from "@mui/material";
import React from "react";

const DivUI = React.forwardRef((props,ref)=> {
  return (
    <Box ref={ref} {...props}>
      <>
      {props.children}
      </>
      </Box>
  );
})

export default DivUI;
