import { Card, CardContent } from "@mui/material";

const CardUI = props => {
  const { children, cardContentProps,cardContentStyle, ...rest } = props;
  return (
    <Card style={{borderRadius:'8px'}} {...rest}>
      <CardContent style={cardContentStyle} {...cardContentProps}>{children}</CardContent>
    </Card>
  );
};

export default CardUI;