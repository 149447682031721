"use client";
import ButtonUI from "@/ui-components/atoms/Button";
import CardUI from "@/ui-components/atoms/Card";
import DivUI from "@/ui-components/atoms/Div";
import TypographyUI from "@/ui-components/atoms/Typography";
import { useRouter } from "next/navigation";
import useMediaQuery from "@mui/material/useMediaQuery";
import { startPageLoader } from "@/redux/slices/uiConfig";
import { useAppDispatch } from "@/redux/hooks";
export default function NotFound() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const router = useRouter();
  const dispatch = useAppDispatch();
  return (
    <CardUI
      sx={{
        boxShadow: "none",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        position: "relative",
      }}
    >
      <DivUI>
        <img
          src="/404img.svg"
          style={{ width: isMobile ? "80vw" : "auto" }}
        ></img>
        <TypographyUI sx={{ mt: "30px" }} font_style={"36-600"}>
          Page not found
        </TypographyUI>
        <TypographyUI
          sx={{ mt: "10px" }}
          font_style={"18-400"}
          color="text.secondary"
        >
          Sorry! We couldn't find the page you were looking for.
        </TypographyUI>
        <ButtonUI
          font_style={"16-500"}
          style={{
            textTransform: "none",
            marginTop: "30px",
          }}
          sx={{
            width: { xs: "100%", md: "350px" },
          }}
          color="gradient"
          variant="fill"
          onClick={() => {
            dispatch(startPageLoader())
            router.push("/")
          }
        }
        >
          <TypographyUI color="white" font_style={"16-500"}>
            Go to home page
          </TypographyUI>
        </ButtonUI>
      </DivUI>
      <img
        style={{ position: "absolute", top: 0, left: 0 }}
        src="/faqtopbg.png"
      ></img>
      <img
        style={{ position: "absolute", bottom: 0, right: 0 }}
        src="/faqbottombg.png"
      ></img>
    </CardUI>
  );
}
