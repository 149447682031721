import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import TypographyUI from "../Typography";

export default function ButtonUI({ children, ...props }) {
  const theme = useTheme();
  const gradientBorderStyle = {
    background: theme.palette.primary.gradientBorder,
    border: "2px solid transparent",
  };

  let newProps = { ...props };

  newProps.color = props.color || "primary";
  newProps.variant = props.variant || "contained";

  if (props.color === "gradient") {
    newProps.sx = {
      background: theme.palette.primary.gradient,
      ...newProps.sx,
    };
    delete newProps.color;
  }
  if (props.color === "gradientBorder") {
    newProps.sx = {
      background: theme.palette.primary.gradientBorder,
      border: "1px solid transparent",
      ...newProps.sx,
    };
    delete newProps.color;
  }
  return (
    <Button
    {...newProps}
      style={{ textTransform: "none", height:"42px", borderRadius:"8px", ...props.style }}
    >
      {children}
    </Button>
  );
}
